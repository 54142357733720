@import 'tailwindcss/base';
@import 'tailwindcss/utilities';

@import 'components/age-gate';

@import 'base/typography';
@import 'base/layout';

.h0 {
  @apply font-black leading-none;
  font-size: clamp(48px, 5vw, 90px);
}

.h0-sm {
  @apply font-black leading-none;
  font-size: clamp(24px, 5vw, 90px);
}

.h1 {
  @apply font-medium leading-none;
  font-size: clamp(40px, 5vw, 85px);
}

.h1-sm {
  @apply font-medium leading-none;
  font-size: clamp(32px, 5vw, 48px);
}

.h2 {
  @apply text-xl;
}

.h2-sm {
  @apply text-lg md:text-xl;
}

main {
  padding: 8vw 8vw 0;
  width: 100%;
  margin: auto 0;
}

.font-black {
  font-weight: 900;
  line-height: 0.6;

  @media (max-width: 768px) {
    line-height: 0.9;
  }
}

.font-medium {
  font-weight: 500;
}

.site-header {
  text-align: right;
  position: relative;
  z-index: 10;

  @media (min-width: 768px) {
    position: absolute;
    top: 36px;
    right: 36px;
  }
}

.site-header-cta {
  display: inline-block;
  border: 2px solid black;
  padding: 12px 20px;
  color: black;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    color: black;
    border-color: #ffe900;
    background: #ffe900;
  }
}

.headline {
  margin: 0;
  font-size: clamp(80px, 23vw, 120px);

  @media (min-width: 768px) {
    font-size: clamp(75px, 10vw, 180px);
    text-align: center;
    line-height: 0;
  }

  div {
    display: inline-block;
    text-align: left;
  }

  br {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  div > span {
    display: block;
    position: relative;
    pointer-events: none;
    height: 1.45em;
    margin: 0 -15px;

    @media (min-width: 768px) {
      display: inline-block;
      width: 4em;
      margin: 0;
      height: 1.5em;
      top: 0.33em;
    }

    &:after {
      content: '';
      position: absolute;
      background: url(images/logo.svg) no-repeat left 60% transparent;
      background-size: contain;
      inset: 0;

      @media (min-width: 768px) {
        background-position: center bottom;
      }
    }

    span {
      text-indent: -999em;
      color: white;
      position: absolute;
    }
  }
}

.main-content {
  padding: 100px 0 60px;

  @media (min-width: 768px) {
    padding: 150px 0;
  }
}

.main-inner {
  position: relative;

  @media (min-width: 768px) {
    padding-left: 8px;
  }
}

.site-content {
  font-size: 20px;
  margin: 48px 0;

  @media (min-width: 768px) {
    padding-top: 12px;
    width: 80%;
    max-width: 550px;
  }

  @media (min-width: 1280px) {
    padding-top: 2.5vw;
    font-size: 24px;
    max-width: 660px;
  }

  p {
    margin: 1em 0;
  }
}

/* form { */
/*   font-size: 21px; */
/*   letter-spacing: 0.035em; */

/*   @media (min-width: 1024px) { */
/*     font-size: 28px; */
/*   } */

/*   @media (min-width: 1280px) { */
/*     font-size: 36px; */
/*   } */

/*   .form-field { */
/*     display: flex; */
/*     margin-top: 16px; */
/*     flex-shrink: 0; */
/*     position: relative; */
/*     flex-wrap: wrap; */
/*     max-width: 420px; */

/*     @media (min-width: 1280px) { */
/*       max-width: 600px; */
/*     } */
/*   } */

/*   #mce-responses { */
/*     margin-top: 16px; */
/*     font-size: 0.75em; */

/*     @media (min-width: 768px) { */
/*       margin-top: 0; */
/*       width: 100%; */
/*       position: absolute; */
/*       left: 0; */
/*       top: calc(100% + 16px); */
/*     } */

/*     a { */
/*       color: black; */

/*       &:hover { */
/*         opacity: 0.7; */
/*       } */
/*     } */
/*   } */

/*   label { */
/*     display: block; */
/*     line-height: 1.2; */
/*   } */

/*   [type='email'] { */
/*     border: none; */
/*     border-bottom: 1px solid black; */
/*     padding: 3px 8px 3px 0; */
/*     height: 48px; */
/*     width: calc(100% - 48px); */
/*     outline: none; */
/*     letter-spacing: 0.035em; */
/*     border-radius: 0; */
/*   } */

/*   [type='submit'] { */
/*     display: flex; */
/*     flex-shrink: 0; */
/*     width: 48px; */
/*     height: 48px; */
/*     background: transparent; */
/*     border: 1px solid black; */
/*     transition: all 0.2s ease-in-out; */
/*     cursor: pointer; */
/*     padding: 0; */
/*     color: black; */

/*     &:hover { */
/*       background: black; */
/*       color: white; */
/*     } */

/*     svg { */
/*       width: 19px; */
/*       width: 15px; */
/*       margin: auto; */
/*     } */
/*   } */
/* } */
