.age-gate {
  position: fixed;
  inset: 0;
  z-index: 1;
  backdrop-filter: blur(60px);
  display: flex;
  font-size: clamp(24px, 5vw, 60px);
  transition: opacity 0.5s ease-in-out;
  flex-wrap: wrap;

  &.is-removing {
    opacity: 0;
    pointer-events: none;
  }
}

.age-gate-inner {
  margin: auto;

  @media (min-width: 768px) {
    width: 75%;
  }

  @media (min-width: 1024px) {
    width: 60%;
  }
}

.age-gate-main {
  background: #ffe900;
  border: 2px solid black;
  width: 100%;
  margin: auto;
  max-width: 800px;
}

.age-gate-bottom {
  width: 100%;
  text-align: center;
  padding-top: 16px;

  @media (min-width: 768px) {
    padding-top: 0;
  }

  button {
    background: transparent;
    padding: 0;
    text-align: center;
    border: none;
    cursor: pointer;
    color: #000;
    font-size: 20px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.age-gate-title {
  text-align: center;
  padding: 36px 16px;
}

.age-gate-options {
  display: flex;
  border-top: 1px solid black;
  font-size: 60%;

  button {
    background: transparent;
    padding: 20px 16px;
    flex: 1 1 auto;
    text-align: center;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: #000;
    white-space: nowrap;

    @media (min-width: 768px) {
      padding: 20px;
    }

    &:hover {
      background: black;
      color: #ffe900;
    }

    &:last-child {
      border-left: 1px solid black;
    }
  }
}
