@font-face {
  font-family: 'Owners';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('fonts/Owners-Regular.woff2') format('woff2'), url('fonts/Owners-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Owners';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('fonts/Owners-Medium.woff2') format('woff2'), url('fonts/Owners-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Owners';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('fonts/Owners-Black.woff2') format('woff2'), url('fonts/Owners-Black.woff') format('woff');
}
